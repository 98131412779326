class productGalleryHelper{
    constructor() {
        
    }
    init(key) {
        setTimeout(() => {
            
            var $div = $('.flex-active');
            const slides = document.querySelectorAll('.flex-control-nav li img');
            const slideLis = document.querySelectorAll('.flex-control-nav li');
            if(slideLis.length > 0){

            const targetLi = slideLis[0];
            const liHeight = $(slideLis[1]).outerHeight(true);
            marginTop = liHeight + 5;
            targetLi.style.marginTop = marginTop + 'px'; 
            const slidesLength = slides.length;
            var observer = new MutationObserver(function(mutations) {
                mutations.forEach(function(mutation) {
                    var attributeValue = $(mutation.target).prop(mutation.attributeName);
                    if(attributeValue == "flex-active"){
                        slideNumber = $(mutation.target).data('slide');
                        marginTop = (-1 * liHeight * (slideNumber-2)) + 5;
                        targetLi.style.marginTop = marginTop + 'px';
                    }
                });
            });
            var i =1;
            slides.forEach(function(slide){
                slide.setAttribute('data-slide',i);
                observer.observe(slide, {
                    attributes: true,
                    attributeFilter: ['class']
                });
                i++;
            });
        }

        }, 500);
            
    }
}
window.app.add('product_gallery', new productGalleryHelper());