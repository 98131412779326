/*

   app.resize.responsively(BreakPoint, MatchCaseFunction, MismatchCaseFunction);

   Function expects MediaQueryList object for breakpoint. Default Bootstrap breakpoints have been defined in main.js
   The syntax for adding a custom breakpoint can be seen there.

   The function that satisfies the current window size will be executed when this function is called. After this initial
   run, the check to check which function to execute is done ONLY when the window crosses the breakpoint boundary.

   To pass arguments to your functions, you must use the .bind() function. Functions passed to the app.resize.responsively
   function lose their scope and have no access to the variables around it. Example:

   var nav = "Hello, World!";
   app.resize.responsively(app.bp.md, function(nav){
      //nav exists as nav.
   }.bind(null, nav), function(){
      //nav doesn't exist in this function.
   });

   The null argument in bind is for the "this" symbol. Overload this if you want to.

*/
class resizeHelper{
    constructor(){

    }
    responsively(breakpoint, matchFunction, mismatchFunction){
        if (typeof matchFunction !== 'function' || typeof mismatchFunction !== 'function' || Object.prototype.toString.call(breakpoint) !== '[object MediaQueryList]') throw "app.resize.responsively(bp, match, mismatch) expects MediaQueryList object, function, function.";
        breakpoint.addListener(
            ((f) => {
                f();
                return f;
            })(() => {
                if (breakpoint.matches) {
                    matchFunction();
                }
                else {
                    mismatchFunction();
                }
            })
        )
    }
}
window.app.add('resize', new resizeHelper());