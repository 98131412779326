class videoHelper{
	constructor() {
		
	}
	vidoeKill() {
		if ($('.modal-video')[0]) {
			$('.modal-video').each(function () {
				var modal = $(this),
					btn = $(this).find('button.close')[0],
					tar = $(this).find('.modal-body')[0];
				$(btn).data('dismiss', false);
				$(btn).click(function (e) {
					e.preventDefault();
					var html = $(tar).html();
					$(tar).html(html);
					modal.modal('hide');
				});
			});
		}
	}
}
window.app.add('video', new videoHelper());