window.$ = jQuery.noConflict(true);
class App {
	constructor() {
		document.addEventListener('DOMContentLoaded', () => {
			// Replace with your required fonts
			require('webfontloader').load({
				google: {
					families: ['Open Sans:100,200,300,400,500,600,700,800,900', 'Raleway:100,200,300,400,500,600,700,800,900']
				}
			});

			this.collect.bind(this)();

			
		});
	}
	add(propertyName, object) {
		this[propertyName] = object;
		(this[propertyName].init || function(){}).bind(this[propertyName])();
	}
	call(signature, element) {
		try {
			this.envoke(((signature) => {
				signature.shift();
				return signature;
			})(signature.split('.')))(element);
		}
		catch (Exception) {
			console.warn(`Error calling ${signature} from `, element, Exception);
		}
	}
	envoke(rest, current = this) {
		return rest.length == 1 ? current[rest.shift()].bind(current) : this.envoke(rest, current[rest.shift()]);

	}
	collect() {
		window.timeStart = window.performance.now();
		for (let element of document.querySelectorAll('[app-call]'))
			this.call(element.getAttribute('app-call'), element);
		console.info(`Execution time: ${window.performance.now() - window.timeStart}ms after DOMContentLoaded.`);
	}
}
window.app = new App();

// Trap focus inside mobile navigation modal
const  focusableElements =
    'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
const modal = document.querySelector('#mobileNavbarCollapse'); // select the modal by it's id

const firstFocusableElement = modal.querySelectorAll(focusableElements)[0]; // get first element to be focused inside modal
const focusableContent = modal.querySelectorAll(focusableElements);
const lastFocusableElement = focusableContent[focusableContent.length - 1]; // get last element to be focused inside modal


document.addEventListener('keydown', function(e) {
  let isTabPressed = e.key === 'Tab' || e.keyCode === 9;

  if (!isTabPressed) {
    return;
  }

  if (e.shiftKey) { // if shift key pressed for shift + tab combination
    if (document.activeElement === firstFocusableElement) {
      lastFocusableElement.focus(); // add focus for the last focusable element
      e.preventDefault();
    }
  } else { // if tab key is pressed
    if (document.activeElement === lastFocusableElement) { // if focused has reached to last focusable element then focus first focusable element after pressing tab
      firstFocusableElement.focus(); // add focus for the first focusable element
      e.preventDefault();
    }
  }
});

firstFocusableElement.focus();