class commonHelper{
    constructor() {
        this.responsiveTable();
        this.hideEmptyFacets();

    }
    hideEmptyFacets() {
        if(typeof FWP !== "undefined"){
            //variable exists, hide empty facets
            $.each(FWP.settings.num_choices, function(key, val) {
                var $parent = $('.facetwp-facet-' + key).closest('.filter');
                (0 === val) ? $parent.hide() : $parent.show();
            });
        }
        else{
            setTimeout(this.hideEmptyFacets, 250);
        }
    }
    slideout() {
        $('#navbarCollapse').on('shown.bs.collapse', function() {
            $('body').addClass('noscroll');
            setTimeout(function() {$('#slideoutlist').focus()}, 300);
        });
        $('#navbarCollapse').on('hide.bs.collapse', function() {
            $('body').removeClass('noscroll');
            $('#brand').focus();
        });
    }
    responsiveTable() {
        var tables = document.getElementsByTagName('table');
        for(i = 0;i < tables.length; i++){
            var item = tables[i];
            // console.log(tables[i]);
            var div = document.createElement('div');
            div.className = 'responsive-table';
            item.parentNode.insertBefore(div, item.nextSibling);
            // item.append(div);
            div.appendChild(item);
            // var tableid = '_' + Math.random();
            // $(item).append('<div class="responsive-table" id="' + tableid + '"></div>');
            // $('_' + tableid).append(item);
        }
    }
    
}
window.app.add('common', new commonHelper());
