class scrollHelper{
	constructor() {
		
	}
	to(el, tar, buff) {
		$(el).click(function (e) {
			e.preventDefault();
			var target = typeof tar === 'undefined' ? $(this).attr('href') : tar,
				buffer = typeof buff === 'undefined' ? parseInt($('body').css('margin-top')) : buff,
				scrollDest = parseInt($(target).offset().top - buffer);
			$('html, body').animate({
				scrollTop: scrollDest,
			}, 1000);
		});
	}
}
window.app.add('scroll', new scrollHelper());